<template>
  <div class="header-menu-categories-root">
    <div class="container">
      <div class="header-menu-categories"
         @mouseenter="onVisibleDropdown"
         @mouseleave="mouseleave"
      >
        <template v-for="(menuCategory, index) in menuCategories">
          <div
            v-if="index > 0"
            class="--delimiter"
          />
          <ItemMenuCategory
            :item="menuCategory"
            :item-in-focus="activeMenuIndex"
            :is-visible-dropdown="isDropdownVisible"
            :item-index="index"

            @updateDropdown="installMenuIndex"
          />
        </template>
      </div>
    </div>
    <div class="header-menu-categories__arrow-down"/>
  </div>
</template>

<script lang="ts" setup>
import {computed, ref} from 'vue';
import { catalogStore } from '#sitis/stores/modules/catalog-store';
import ItemMenuCategory from './_ItemMenuCategory.vue';
import { useAsset } from '~/composables/useAssets';

const storeCatalog = catalogStore();

const menuCategories = computed(() => {
  let categories = storeCatalog?.$state?.categories || [];
  categories = categories.map((item: any) => ({
    ...item,
    items: (item?.items || []).filter((t: any) => t?.showInMenu)
  }))
    .filter((t: any) => t?.showInMenu)
    .filter((t: any) => t.depth < 4);

  let list: any = categories.filter((t) => t.depth === 1);
  list = list.map((item: any) => {
    const children = categories
      .filter((t: any) => t.parentId === item.id)
      .map((t: any) => {
        return {
          ...t,
          children: categories.filter((_t: any) => _t.parentId === t.id)
        }
      })

    return {
      ...item,
      children: children
    }
  });

  let indexKomnataDljaPodrostka = list.findIndex((t: any) => t.slug === 'komnata-dlja-podrostka');
  const imageLink = useAsset("img/jpg/header/miasofiahome.jpg");
  list.splice((indexKomnataDljaPodrostka + 1), 0, {
    url: "https://miasofiahome.ru",
    name: "Мебель для родителей",
    imageUrl: imageLink,
    children: [
      {name: "Сайт мебели для родителей", url: "https://miasofiahome.ru", target: "_blank", imageUrl: imageLink},
      {name: "Гостиная", url: "https://miasofiahome.ru/catalog/komnaty/gostinaya", target: "_blank", imageUrl: imageLink},
      {name: "Спальня", url: "https://miasofiahome.ru/catalog/komnaty/spalnya", target: "_blank", imageUrl: imageLink},
      {name: "Прихожая", url: "https://miasofiahome.ru/catalog/komnaty/prihozhaya", target: "_blank", imageUrl: imageLink},
      {name: "Мягкая мебель", url: "https://miasofiahome.ru/catalog/divany-i-kresla", target: "_blank", imageUrl: imageLink},
    ]
  });

  // list.push({
  //   url: "/akcii",
  //   name: "Скидки"
  // });

  return list;
});

const isDropdownVisible = ref<boolean>(false);
let timeOutVisibleDropdown: any = null;
const onVisibleDropdown = () => {
  const findDropDown = document.querySelector('.item-menu-dropdown');
  let _timeout = 0;
  if (!findDropDown) {
    _timeout = 250;
  }
  clearTimeout(timeOutVisibleDropdown);
  timeOutVisibleDropdown = setTimeout(() => {
    if (activeMenuIndex.value !== -1) {
      isDropdownVisible.value = true;
    }
  }, _timeout);
};
const mouseleave = () => {
  isDropdownVisible.value = false;
  activeMenuIndex.value = -1;
}
const activeMenuIndex = ref(-1);
const installMenuIndex = function(newIndex: number) {
  activeMenuIndex.value = newIndex;
  if (newIndex === -1) {
    mouseleave()
  }
}
</script>

<style lang="scss">
.header-menu-categories-root {
  position: relative;
}

.header-menu-categories__arrow-down {
  position: absolute;
  left: -1px; right: -1px; bottom: -24px;
  height: 64px;
  border-radius: 0 0 40px 40px;
  border: 1px solid var(--line-color-border-smoky-white, #EBEBEB);
  border-top: initial;
  pointer-events: none;
}

.header-menu-categories {
  display: flex;
  align-items: center;

  .--delimiter {
    width: 1px;
    height: 18px;
    background-color: #EBEBEB;
  }
  .item-menu-category {
    display: flex;
    flex: auto;

    .item-menu-category__head {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: auto;
    }
    &:first-child {
      flex: 0.5 auto;
      .item-menu-category__head {
        justify-content: flex-start;
      }
    }
    &:last-child {
      flex: 0.5 auto;
      .item-menu-category__head {
        justify-content: flex-end;
      }
    }
  }
}

@media (max-width: 1439px) {
  .header-menu-categories__arrow-down {
    border-radius: 0 0 30px 30px;
  }
}
@media (max-width: 1199px) {
  .header-menu-categories__arrow-down {
    bottom: -16px;
  }
}
</style>
