<template>
  <div
    ref="refParent"
    class="item-menu-category"
  >
    <nuxt-link
      :to="item.url"
      class="item-menu-category__head hover-decoration"
      :class="{'--small': isHeaderFixed}"
      :target="targetLink"
      rel="preload"
      @mouseenter="updateVisibleDropdown"
      @click="() => clickChildren(item.url)"
    >
      {{ item.name }}
    </nuxt-link>

    <client-only>
      <div
        v-if="isShowDropdown && showChoosingDropdown"
        class="item-menu-dropdown"
        :class="{'--fixed-header': isHeaderFixed}"
      >
        <template v-if="!isDropdownFurnitureItems">
          <div class="--delimiter"></div>
          <div class="container">
            <div
              class="item-menu-dropdown__content"
              @mouseleave="() => childrenMouseLeave()"
            >
              <div class="--left item-menu-dropdown__children">
                <template
                  v-for="(_item) in item.children"
                  :key="`item-menu-dropdown-${_item.url}`"
                >
                  <nuxt-link
                    :to="_item.url"
                    :target="_item.target || ''"
                    @click="() => clickChildren(_item.url)"
                    @mouseover="() => childrenMouseOver(_item)"
                  >
                    {{ _item.name }}
                    <img
                      src="~/assets/img/svg/common/arrow.svg"
                      alt="перейти"
                    >
                  </nuxt-link>
                </template>
              </div>
              <div class="--center item-menu-dropdown__image">
                <nuxt-img
                  v-if="childrenHover && childrenHover.imageId && !childrenHover?.imageUrl"
                  :src="String(childrenHover.imageId)"
                  width="520px"
                  loading="lazy"
                  placeholder
                  fit="cover"
                  :alt="`item-menu-dropdown-children-hover-image-${childrenHover.imageId}`"
                />
                <img
                  v-if="childrenHover?.imageUrl"
                  :src="String(childrenHover.imageUrl)"
                  loading="lazy"
                  :alt="`item-menu-dropdown-children-hover-image-${childrenHover.imageUrl}`"
                />
              </div>
              <div class="--right"></div>
            </div>
          </div>
        </template>
        <template v-if="isDropdownFurnitureItems">
          <DropdownFurnitureItems
            :item="item"

            @clickChildren="clickChildren"
          />
        </template>
      </div>
    </client-only>

    <div
      v-if="isShowDropdown && showChoosingDropdown"
      class="item-menu-dropdown__overlay"

      @mouseenter="hideDropdown"
    />
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { useCommonStore } from '~/store/common';

const props = defineProps({
  item: {
    type: Object,
    default: () => ({})
  },
  active: {
    type: Boolean
  },
  isVisibleDropdown: {
    type: Boolean,
  },
  itemInFocus: {
    type: Number,
  },
  itemIndex: {
    type: Number,
  }
});
const $route = useRoute();

const commonStore = useCommonStore();
const DropdownFurnitureItems = defineAsyncComponent(() => import('./DropdownFurnitureItems.vue'));

const refParent = ref<HTMLDivElement | null>(null);

const targetLink = computed(() => {
  return (props.item?.url || '').includes('https://') ? '_blank' : '';
});

const emit = defineEmits(['updateDropdown']);

const isHeaderFixed = computed(() => commonStore._headerFixed);
const isShowDropdown = computed(() => {
  return Boolean((props.item?.children || []).length > 0);
});

const isDropdownFurnitureItems = computed(() => {
  return Boolean(props.item?.slug === 'predmety-mebeli');
});

const childrenHover: any = ref({});
const childrenMouseOver = (child: any) => {
  childrenHover.value = child;
};
const childrenMouseLeave = () => {
  childrenHover.value = props.item?.children?.[0];
};
onMounted(() => {
  childrenHover.value = props.item?.children?.[0];
});

const clickChildren = (newUrl?: string) => {
  const currentUrl = (($route.path || '').split('/f/')?.[0] || '').split('?')?.[0] || '';
  if (currentUrl === newUrl) {
    window.location.href = newUrl;
    return null;
  }

  if (refParent.value) {
    hideDropdown()
    refParent.value.style.pointerEvents = 'none';

    setTimeout(() => {
      if (refParent.value) {
        refParent.value.style.pointerEvents = '';
      }
    }, 600);
  }
};
const updateVisibleDropdown = () => {
  emit('updateDropdown', props.itemIndex);
};

const hideDropdown = () => {
  emit('updateDropdown', -1);
};

const showChoosingDropdown = computed(() => {
  return props.itemIndex === props.itemInFocus && props.isVisibleDropdown;
})
</script>

<style lang="scss">
.item-menu-category {
  position: relative;
  margin-left: -2px;
	&:hover .item-menu-dropdown {
		display: block;
	}
}

.item-menu-category__head {
	margin-top: -20px;
	margin-bottom: -23px;
	padding-top: 20px;
	padding-bottom: 23px;
	box-sizing: border-box;

	cursor: pointer;
	color: #000;
	font-size: 16px;
	font-weight: 500;
	line-height: 140%;
	white-space: nowrap;

	&.--small {
		margin-bottom: -16px;
		padding-bottom: 16px;
	}
}

.item-menu-dropdown {
	display: none;
	position: fixed;
	z-index: -1;
	top: 140px;
	background-color: white;
	left: 0;
	right: 0;
	padding-top: 37px;
	box-sizing: border-box;
	border-radius: 0 0 30px 30px;
	box-shadow: 0px 27px 23px -14px rgba(0, 0, 0, 0.05);

	.container {
		padding: 0 80px;
	}

	.--delimiter {
		width: 100%;
		height: 1px;
		background-color: #EBEBEB;
  }

	&.--fixed-header {
		top: 80px;
  }
}

.item-menu-dropdown__overlay {
  position: relative;
  z-index: -2;
  &:before {
    content: '';
    position: fixed;
    top: 150px;
    left: 0;
    min-width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: -1;
  }
}

.item-menu-dropdown__content {
	display: flex;
	padding: 32px 0;
	box-sizing: border-box;

  .--left {
		flex: 1;
		max-width: 380px;
	}

	.--center {
		flex: 1;
		max-width: 380px;
	}

	.--right {
		flex: 1;
	}
}

.item-menu-dropdown__children {
	display: flex;
	flex-direction: column;

	& > * {
		display: flex;
		align-items: center;
		color: #535353;
		padding: 8px 0;
		box-sizing: border-box;

		img {
			display: none;
			width: 14px;
			height: 14px;
			margin-left: 6px;
			object-fit: contain;
			transform: rotate(180deg);
		}

		&:hover {
			font-weight: 600;
			color: #000;

			img {
				display: block;
			}
		}
	}
}

.item-menu-dropdown__image {
	position: relative;
	height: 240px;

	img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		border-radius: 10px;
	}
}

@media (max-width: 1199px) {
	.item-menu-category__head {
		padding-top: 15px;
		padding-bottom: 15px;

		margin-top: -15px;
		margin-bottom: -15px;

		font-size: 14px;
	}
	.item-menu-dropdown__content .--left {
		max-width: 460px;
	}
	.item-menu-dropdown__content .--right {
		display: none;
	}
}
</style>
